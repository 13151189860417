/* floating action button */
.chat-fab {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 1em;
    border-radius: 100%;
    width: 2em;
    height: 2em;
    background-color: #0974e6;
    color: white;
    font-size: x-large;
    border: none;
  }
  