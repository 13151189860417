.input_margin{
    margin-bottom: 50px;
}


.form-input{
    border-radius: 5px;
    margin: 4vh;
    zoom: 120%;
}

.create-user-container {
    display: flex;
    justify-content: center;
}

.form-container{
    /* border: 6px solid black; */
    /* padding: 20vh; */
    width: 15vh;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75); */
    /* background-color: #4fdeb08a; */
}

.title{
    /* color: #3f74abc7; */
    margin: 2vh;
}