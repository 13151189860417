.AIvsAI-background{
    padding: 20px;
    box-shadow: 10px 10px 50px rgb(187 173 173);
    border-radius: 10px;
}

.AIvsAI-text-snake-name{
    text-align: left;
    margin: 10px;
}

.AIvsAI-text-snake-win{
    text-align: right;
    margin: 10px;
}

.AIvsAI-skin-button-container{
    display: flex;
    justify-content: flex-end;
}

.AivsAi-game{
    box-shadow: 10px 10px 50px rgb(187 173 173);
    width: 300px;
    height: 300px;
}