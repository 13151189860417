
.blog-background{
    background-color: hsl(255, 98%, 98%);
    border: 100px;
    height: 100%;
    box-shadow: 6px 14px 82px -6px rgba(0,0,0,0.57);
    padding-top: 60px;
}

.blog-big-title{
    margin-bottom: 60px;
}

.blog-container-all{
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.blog-container{
    width: 100vh;
    margin-bottom: 60px;
    box-shadow: 10 10 black;
    box-shadow: 3px 10px 20px 6px rgb(0 0 0 / 15%);
}


.blog-AI-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}


 /* text */

.blog-text-container{
    position: relative;
    height: 40vh;
}

.blog-title{

}

.blog-content{
    text-align: start;
    font-weight: 650;
}

.blog-date{
    position: absolute;
    bottom: 0;
    right: 0;
}

.blog-button{
    position: absolute;
    bottom: 0;
    left: 0;
    zoom: 0.9;
    margin: 3px;
}