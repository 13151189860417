.Introductin-QTable-img {
    display: flex;
    justify-content: flex-end;
    margin: 50px;
}

.Introductin-QTable-img img {
    max-height: auto;
    max-width: auto;
}

.Introductin-Deep-Q-img {
    display: flex;
    justify-content: flex-end;
}

.imageSize {
    display: inline;
}

.imageSize img {
    max-height: 270px;
}

.Introduction-img-max-size {
    max-width: 100vh;
}

@media screen and (max-width: 780px) {
    .Introduction-img-max-size {
        max-width: 40vh;
    }
}


.Introduction-Q-and-deepQ-content {
    font-size: large;
}

.Introduction-deepQ-content {

}