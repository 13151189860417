

.vsAi-container-background{
    box-shadow: 10px 10px 50px rgb(187 173 173);
    padding: 5px;
    border-radius: 10px;
}

.vsAi-game{
    box-shadow: 6px 14px 82px -6px rgba(27, 27, 27, 0.329);
    width: 300px;
    height: 300px;
}

.count-text{
    font-weight: 650;
    text-align: left;
}

.vsAi-button{
    margin: 7px;
    font-size: 10px;
    zoom: 1.05;
}

.game-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.vsAI-s1-progress-bar{

}

.vsAI-s2-progress-bar{

}

.vsAI-progress-bar-text{
    margin-top: 30px;
    text-align: start;
}

