
.App {
  text-align: center;
  /* background-color: hsla(251, 24%, 91%, 0.473); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App{
  /* display: inline-block; */
  /* background-color: #d0cde093; */
  /* height: 100vh; */
  /* width: 100vh; */
}

.navbar{
  align-items:unset!important;
  justify-content: unset!important;
  border-bottom: 1vh;
  background-image: linear-gradient(to right, rgb(183 133 251), rgb(255 150 150));
  z-index: 100px;
}

/* .app-nav-bar{
  width: 100%;
  height: 7vh;
} */

.nav-logIn-container{
  display: flex;
  justify-content: flex-end;
}

.app-row {
  display: flex;
}

.app-row .grow {
  flex-grow: 1;
}
.app-row  .auto {
  flex-grow: 0;
  flex-shrink: 1;
}

.home-page-game-container{
  display: flex;
  flex-wrap: wrap;
}

.home-page-game{
  zoom: 40%;
  margin: 20px;
  border: 10px #4f4949 solid;
  opacity: 0.85;
}

.opacity-text{
  opacity: 0.5; 
}

.home-page-game-text{
  font-weight: 1000;
}

.home-page-game-soon{
  zoom: 110%;
  margin: 10px;
  border: 5px;
  opacity: 0.85;

}

.link-text{
  color: #e4e2ed;
  font-weight: 500;
}

.background{
  padding: 1vh;
  border: 100px;
  height: 90vh;
}