.chatroom {
    background-color: thistle;
    border: 2px solid blueviolet;
    border-bottom: 0;
    border-radius: 5%;
  }
  .chatroom .content{
      display: flex;
      flex-direction: column;
      height: 360px;
      overflow:auto;
      padding-right: 25px;
  }

  
  .chatroom.header{
    border-radius: 80%
}



  .chatroom .inputs{
      height: 2em;
      padding-left: 15px;
      padding-right: 20px;
      position:relative;
      bottom:-1px
  }
  .chatroom .inputs button {
      height: 2.3em;
      padding: 0;
      margin: 3px;

  }