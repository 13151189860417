.home-page-game:hover {
    box-shadow: 0 0 40px rgba(33,33,33,.2); 
    /* height: 100%;
    width:100%; */
    zoom: 0.45;
  }

  /* .background{
    background-color: #d0cde093;
    border: 100px;
    height: 97vh;
  } */

  .HomePage-background{
    /* background-color: #d4d2e454; */
    border: 100px;
    height: 100%;
  }

  .box-shadow{
    box-shadow: 6px 14px 82px -6px rgba(0,0,0,0.57);
  }
  
  
  .home-page-game-container{
    display: flex;
    justify-content: center;
    height: 250px;
}
/* top  */


.Top-container{
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.opacityText{
  opacity: 0.6;
  text-align:left;
}

.tittleText{
  color: #4f4f53;
  text-align:left;
}

.button-container{
  
}

.button{
  zoom: 2;
  margin-top: 7px;
}

.button-text{
  font-size: 9px;
  /* opacity: 0.8; */
  color: #ffffffde;
}

/* AI */

.game{
  height: 300px;
  width: 300px;
}


/* bottom */

.bottom-container{
}