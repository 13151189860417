.search-user {
    background-color: wheat;
    padding: 0.5em;
    border-radius: 10%;
  }
  .search-user .title {
    font-weight: 600;
    padding: 0.5em;
  }
  
  .search-user .chat-btn {
    padding: 0;
    margin-top:0.2em;
    /* margin-bottom:0.2em;
    margin-right:0.5em; */
  }
  .search-user .name {
    text-align: left;
    padding-left: 40px;
  }
  .searchAndFriend{
    display: flex;
    justify-content: space-between;
}
  